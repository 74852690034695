import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from './SEO';
import Header from './Header';
import Footer from './Footer';

// Styles
import '../assets/styles/main.scss';

const Layout = ({ children, title }) => (
    <>
      <SEO title={title} />

      <Header />
      <div className="root">{children}</div>
      <Footer />
    </>
  );

export default Layout;
