import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';

import imageX1 from '../assets/images/home-page/test/Illustration-build-1x.png';
import imageX2 from '../assets/images/home-page/test/Illustration-build-2x.png';
import imageSvg from '../assets/images/home-page/test/Illustration-build.svg';

const test = () => (
    <Layout>
      <div className="container">
        <div className="row">
          <div className="col-md-7 mx-auto">
            <h5>Gatsby 2x image (124kb)</h5>
            <StaticImage
              className="illustration"
              src="../assets/images/home-page/Illustration-build.png"
              alt="Build"
            />
          </div>
          <div className="col-md-7 mx-auto">
            <h5>svg image (496kb)</h5>
            <img className="w-100" src={imageSvg} alt="" />
          </div>
          <div className="col-md-7 mx-auto">
            <h5>1x image with css (60kb)</h5>
            <img className="w-100 illustration" src={imageX1} alt="" />
          </div>
          <div className="col-md-7 mx-auto">
            <h5>2x image with css (153kb)</h5>
            <img className="w-100 illustration" src={imageX2} alt="" />
          </div>
          <div className="col-md-7 mx-auto">
            <h5>1x image (60kb)</h5>
            <img className="w-100" src={imageX1} alt="" />
          </div>
          <div className="col-md-7 mx-auto">
            <h5>2x image (153kb)</h5>
            <img className="w-100" src={imageX2} alt="" />
          </div>
        </div>
      </div>
    </Layout>
  );

export default test;
