import React, { useState, useEffect } from 'react';
import { use100vh } from 'react-div-100vh';
import classNames from 'classnames';

import { Link } from './atoms';

import * as Routes from '../constants/routes';

import imageLogo from '../assets/images/logo/logo-sm.svg';
import IconDashboard from '../assets/images/icons/icon_dashboard.inline.svg';
import IconArrowDownBig from '../assets/images/icons/icon_arrow_down_big.inline.svg';
import IconCross from '../assets/images/icons/icon/common/close.inline.svg';
import IconArrowDown from '../assets/images/icons/icon/arrow/down.inline.svg';
import IconPlatformOverview from '../assets/images/icons/icon/common/archive.inline.svg';
import IconVisualBuilder from '../assets/images/icons/icon/common/visual-builder.inline.svg';
import IconApiBuilder from '../assets/images/icons/icon/common/pull-request.inline.svg';
import IconControlHub from '../assets/images/icons/icon/common/exchange-hub.inline.svg';
import IconCloudMapper from '../assets/images/icons/icon/common/control-hub.inline.svg';

const Header = () => {
  const [isOpenMobileMenu, setStateMobileMenuOpen] = useState(false);
  const height = use100vh();

  const handleStateMobileMenu = () => {
    setStateMobileMenuOpen(!isOpenMobileMenu);

    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if (isOpenMobileMenu) {
      body.style.overflow = 'auto';
      body.setAttribute('style', 'overflow: auto; touch-action: auto; -ms-touch-action: auto;');
      html.setAttribute('style', 'overflow: auto;');
    } else {
      body.setAttribute(
        'style',
        'overflow: hidden; touch-action: none; -ms-touch-action: none; position: relative; height: 100%;'
      );
      html.setAttribute('style', 'overflow: hidden; position: relative; height: 100%;');
    }
  };

  useEffect(() => {
    if (!isOpenMobileMenu) {
      const body = document.querySelector('body');
      body.style.overflow = 'auto';
    }
  }, [isOpenMobileMenu]);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header__wrapper">
            <div className="header__logo">
              <Link to="/">
                <img src={imageLogo} alt="Logo" />
              </Link>
            </div>
            <div className="header__primary-nav">
              <nav className="primary-nav">
                <Menu isOpenMobileMenu={isOpenMobileMenu} />
              </nav>
            </div>
            <div className="header__mobile-btn" onClick={handleStateMobileMenu}>
              {isOpenMobileMenu ? <IconCross style={{ margin: 3 }} /> : <IconDashboard />}
            </div>
            <div className="header__auth-btn">
              <div className="auth-btn__wrapper">
                <a
                  className="c-btn c-btn--white c-btn--light header-100"
                  href="https://app.jetic.io/login"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Login
                </a>
                <a
                  className="c-btn c-btn--primary c-btn--light header-100"
                  href="https://app.jetic.io/registration"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Register
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      {isOpenMobileMenu && (
        <div className="header header--mobile" style={{ height: `calc(${height}px - 64px)` }}>
          <div className="header__mobile">
            <div className="header__mobile__primary-nav">
              <nav className="primary-nav">
                <MenuMobile isOpenMobileMenu={isOpenMobileMenu} />
              </nav>
            </div>
          </div>

          <div className="header__mobile__auth-btn">
            <a
              className="c-btn c-btn--white header-200 "
              href="https://app.jetic.io/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              Login
            </a>
            <a
              className="c-btn c-btn--primary header-200 mt-3"
              href="https://app.jetic.io/registration"
              target="_blank"
              rel="noopener noreferrer"
            >
              Register
            </a>
          </div>
        </div>
      )}
    </>
  );
};

const MENU_DATA = [
  {
    name: 'Platform',
    dropdown: [
      {
        name: 'Platform Overview',
        description: 'Jetic helps you build scalable integrations and APIs as microservices',
        icon: <IconPlatformOverview />,
        link: Routes.Platform,
      },
      {
        name: 'Camel Studio',
        description: 'Low-code GUI to quickly build powerful Apache Camel routes',
        icon: <IconVisualBuilder />,
        link: Routes.CamelStudio,
      },
      {
        name: 'API Builder',
        description: 'Rapidly design and prototype complex APIs without coding',
        icon: <IconApiBuilder />,
        link: Routes.ApiBuilder,
      },
      {
        name: 'Cloud Data Mapper',
        description: 'Map and transform your data with a simple to use point-and-click tool',
        icon: <IconCloudMapper />,
        link: Routes.CloudMapper,
      },
      {
        name: 'Control Hub',
        description: 'Deploy, manage and monitor your APIs & Integrations at one place',
        icon: <IconControlHub />,
        link: Routes.ControlHub,
      },
    ],
  },
  {
    name: 'Use Cases',
    dropdown: [
      {
        name: 'Real-time Integration',
        description: 'Build and manage your own Integration Hub and connect anything seamlessly',
        icon: <IconPlatformOverview />,
        link: Routes.RealTimeIntegration,
      },
      {
        name: 'API Build & Design',
        description: 'Share your data by exposing scalable APIs directly on Kubernetes',
        icon: <IconPlatformOverview />,
        link: Routes.ApiBuildAndDesign,
      },
      {
        name: 'Serverless iPaaS',
        description: 'Build true, scalable serverless platforms purely using open-source',
        icon: <IconPlatformOverview />,
        link: Routes.ServerlessIpass,
      },
      {
        name: 'Data Mapping',
        description: 'Cloud Data Mapping for complex data transformation tasks',
        icon: <IconPlatformOverview />,
        link: Routes.DataMapping,
      },
      {
        name: 'Event Streaming',
        description: 'Reliable IoT and streaming data integration. Supports Kafka.',
        icon: <IconPlatformOverview />,
        link: Routes.EventStreaming,
      },
      {
        name: 'Data Lakes',
        description: 'Build Data Lakes on Snowflake or SQL databases with pre-built components.',
        icon: <IconPlatformOverview />,
        link: Routes.DataLakes,
      },
      {
        name: 'Data Governance',
        description: 'Manage and secure your data flows and integrations',
        icon: <IconPlatformOverview />,
        link: Routes.DataGovernance,
      },
    ],
  },
  {
    name: 'Developers',
    dropdown: [
      {
        name: 'How does Jetic work?',
        description: 'Learn how Jetic helps you build your Integrations and APIs.',
        icon: <IconPlatformOverview />,
        link: Routes.HowDoesJeticWork,
      },
      {
        name: 'Why Apache Camel?',
        description: 'Learn more about the most mature Integraion Framework',
        icon: <IconPlatformOverview />,
        link: Routes.WhyApacheCamel,
      },
      {
        name: 'Jetic Architecture',
        description: 'Our Architecture was designed with Enterprise in mind. Learn more.',
        icon: <IconPlatformOverview />,
        link: Routes.JeticArchitecture,
      },
      {
        name: 'Quickstart Guide',
        description: 'Get up and running in minutes using our Quickstart Guide.',
        icon: <IconPlatformOverview />,
        link: Routes.QuickstartGuide,
      },
      {
        name: 'Documentation',
        description: 'Lookup technical details and find guides for all Cloud providers.',
        icon: <IconPlatformOverview />,
        link: Routes.Documentation,
      },
      {
        name: 'Support',
        description: 'Easy access to our Engineering team if you need help from a human.',
        icon: <IconPlatformOverview />,
        link: Routes.Support,
      },
    ],
  },
  {
    name: 'Company',
    dropdown: [
      {
        name: 'About Us',
        description:
          'Datagrate is the company behind Jetic. Learn more about our deep expertise in Integration.',
        icon: <IconPlatformOverview />,
        link: Routes.AboutUs,
      },
      {
        name: 'Careers',
        description: 'Help us build Jetic and our customers to implement smart Integration Hubs.',
        icon: <IconPlatformOverview />,
        link: Routes.Careers,
      },
      {
        name: 'Contact Us',
        description: 'We are always there for our customers and partners.',
        icon: <IconPlatformOverview />,
        link: Routes.Legal,
      },
      {
        name: 'Legal',
        description: 'Our Terms of Service and Privacy Policy',
        icon: <IconPlatformOverview />,
        link: Routes.Legal,
      },
    ],
  },
];

const ArrowDown = ({ isOpenMobileMenu }) =>
  isOpenMobileMenu ? <IconArrowDownBig /> : <IconArrowDown />;

const DropdowmMenuItem = ({ data: { name, description, icon, link } }) => (
  <li>
    <Link to={link} className="dropdown-menu__link">
      <div className="dropdown-menu__link__icon">{icon}</div>
      <div className="dropdown-menu__link__content">
        <div className="dropdown-menu__link__content__title">
          <span className="text-sm-b">{name}</span>
        </div>
        <div className="text-sm-r dropdown-menu__link__content__description">{description}</div>
      </div>
    </Link>
  </li>
);

const Menu = ({ isOpenMobileMenu }) => (
  <ul>
    {MENU_DATA.map((rootItem, rootIndex) => (
      <li className="primary-nav__hover" key={rootIndex}>
        <div className="nav-link">
          {rootItem.name}
          <ArrowDown isOpenMobileMenu={isOpenMobileMenu} />
        </div>

        {rootItem?.dropdown?.length && (
          <div className="primary-nav__dropdown">
            <div className="dropdown-menu">
              <ul>
                {rootItem.dropdown?.map((childItem, childIndex) => (
                  <DropdowmMenuItem data={childItem} key={childIndex} />
                ))}
              </ul>
            </div>
          </div>
        )}
      </li>
    ))}
    <li>
      <Link to="/blog"><div className='nav-link pr-3'>Blog</div></Link>
    </li>
    <li>
      <a
        href="https://datagrate-talent.freshteam.com/jobs"
        target="_blank"
        rel="noopener noreferrer"
      >
        <span className="c-label c-label--primary header-100">HIRING</span>
      </a>
    </li>
  </ul>
);

const MenuMobile = () => {
  const [openDropdown, setOpenDropdown] = useState('');
  return (
    <ul>
      {MENU_DATA.map((rootItem, rootIndex) => (
        <li
          className={classNames('primary-nav__item', {
            'primary-nav__item--active': openDropdown === rootItem.name,
          })}
          key={rootIndex}
        >
          <div
            className="nav-link"
            onClick={() => setOpenDropdown(openDropdown === rootItem.name ? '' : rootItem.name)}
          >
            {rootItem.name}
            <ArrowDown isOpenMobileMenu={false} />
          </div>

          {rootItem?.dropdown?.length && (
            <div className="primary-nav__item__dropdown">
              <div className="dropdown-menu">
                <ul>
                  {rootItem.dropdown?.map((childItem, childIndex) => (
                    <DropdowmMenuItem data={childItem} key={childIndex} />
                  ))}
                </ul>
              </div>
            </div>
          )}
        </li>
      ))}
      <li className={classNames('primary-nav__item',)}>
        <Link to="/blog"><div className='nav-link pr-3'>Blog</div></Link>
      </li>
      {/* <li className="primary-nav__item">
        <div className="nav-link">
          <div>
            <a
              className="me-3"
              href="https://datagrate.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              Company
            </a>
            <a
              href="https://datagrate-talent.freshteam.com/jobs"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="c-label c-label--primary header-100">HIRING</span>
            </a>
          </div>
        </div>
      </li> */}
    </ul>
  );
};

export default Header;
